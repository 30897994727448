import { useCallback, useEffect } from 'react';

import { logStore } from '@store';
import { logHttpService } from '@service';

export class LogHook {
  useGetListCallback() {
    const [{ load, query }, setLog] = logStore.useState();

    return useCallback(async () => {
      if (load === false) {
        return;
      }

      const res = await logHttpService.getList(query);

      if (res.ok) {
        setLog((prev) => ({
          ...prev,
          load: false,
          list: {
            ...prev.list,
            total: res.data.total,
            query: res.data.query,
            rows: [...prev.list.rows, ...res.data.rows],
          },
        }));
      }
    }, [load, query, setLog]);
  }

  useMount() {
    const getList = this.useGetListCallback();

    useEffect(() => {
      getList();
    }, [getList]);
  }

  useUnMount() {
    const resetStore = logStore.useResetState();

    useEffect(() => {
      return () => {
        resetStore();
      };
    }, [resetStore]);
  }

  useScrollEnd(scrollEnd: boolean) {
    const setState = logStore.useSetState();

    useEffect(() => {
      if (scrollEnd === false) {
        return;
      }

      setState((prev) => {
        const skip = prev.query.skip + prev.query.take;

        if (prev.list.total > skip) {
          return { ...prev, load: true, query: { ...prev.query, skip } };
        }

        return prev;
      });
    }, [scrollEnd, setState]);
  }
}

export const logHook = new LogHook();
