import { RecoilStore } from '@core';
import { LOG_LIST, LOG_QUERY } from '@service';

import { LogStoreProps } from './types';

export class LogStore extends RecoilStore<LogStoreProps> {
  constructor() {
    super(LogStore.name, {
      load: true,
      list: LOG_LIST,
      query: LOG_QUERY,
    });
  }
}

export const logStore = new LogStore();
