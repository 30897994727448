import { FunctionComponent } from 'react';

import { Box, Typography } from '@mui/material';

export const BlockPage: FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '80vh',
      }}
    >
      <Typography component="h1" variant="h4" sx={{ marginY: 2 }}>
        페이지에 접속할 수 없습니다.
      </Typography>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
};
