import { FunctionComponent } from 'react';

import { Box } from '@mui/material';

import { logHook } from '@hook';

import { LogPageTable } from './components';

export const LogPage: FunctionComponent = () => {
  logHook.useMount();
  logHook.useUnMount();

  return (
    <Box
      sx={{
        mt: 2,
        paddingX: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <LogPageTable />
    </Box>
  );
};
