import { HttpService } from '@core';

import { LogList, LogListQuery } from './types';

export class LogHttpService extends HttpService {
  async getList(query: LogListQuery) {
    return this.get<LogList>(this.url(), { params: query, delay: 250 });
  }
}

export const logHttpService = new LogHttpService('logs');
