import { LogList, LogListQuery } from './types';

export const LOG_QUERY: LogListQuery = {
  skip: 0,
  take: 30,
  ip: 'omit',
};

export const LOG_LIST: LogList = {
  total: 0,
  rows: [],
  query: LOG_QUERY,
};
