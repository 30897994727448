import { DateTime } from 'luxon';
import { ChangeEvent, FunctionComponent, useCallback } from 'react';

import { Box } from '@mui/material';

import { DateInput } from '@component';
import { timeLayoutStore } from '@store';

export const TimeRecordDateRangeGroup: FunctionComponent = () => {
  const [{ date }, setTimeRecordLayout] = timeLayoutStore.useState();

  const onChangeStartDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      setTimeRecordLayout((prev) => {
        const start = DateTime.fromJSDate(new Date(e.target.value));
        const end = DateTime.fromJSDate(new Date(prev.date.e));

        if (end.diff(start, 'months').get('months') < 2) {
          return { ...prev, date: { ...prev.date, s: e.target.value } };
        }

        return {
          ...prev,
          date: {
            ...prev.date,
            s: start.toSQLDate() as string,
            e: start.plus({ months: 1 }).endOf('month').toSQLDate() as string,
          },
        };
      }),
    [setTimeRecordLayout],
  );

  const onChangeEndDate = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      setTimeRecordLayout((prev) => {
        const start = DateTime.fromJSDate(new Date(prev.date.e));
        const end = DateTime.fromJSDate(new Date(e.target.value));

        if (end.diff(start, 'months').get('months') < 2) {
          return { ...prev, date: { ...prev.date, s: e.target.value } };
        }

        return {
          ...prev,
          date: {
            ...prev.date,
            s: end.minus({ months: 1 }).startOf('month').toSQLDate() as string,
            e: end.toSQLDate() as string,
          },
        };
      }),
    [setTimeRecordLayout],
  );

  return (
    <Box sx={{ display: 'flex', maxWidth: 400, alignItems: 'center', gap: 2 }}>
      <DateInput value={date.s} size="small" max={date.e} onChange={onChangeStartDate} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>~</div>
      <DateInput value={date.e} size="small" min={date.s} onChange={onChangeEndDate} />
    </Box>
  );
};
