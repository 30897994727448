import { FunctionComponent } from 'react';

import { TableHead, TableRow } from '@mui/material';

import { TableValueCell } from '@component';

export const LogPageTableHead: FunctionComponent = () => {
  return (
    <TableHead>
      <TableRow>
        <TableValueCell value="ID" />
        <TableValueCell value="IP" />
        <TableValueCell value="x-forwarded-for" />
        <TableValueCell value="Method" />
        <TableValueCell value="경로" />
        <TableValueCell value="상태" fixedWidth />
        <TableValueCell value="요청일시" fixedWidth />
        <TableValueCell value="응답일시" fixedWidth />
      </TableRow>
    </TableHead>
  );
};
