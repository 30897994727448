import { Fragment, FunctionComponent, useCallback, useState } from 'react';

import { IconButton, TableCell, TableRow } from '@mui/material';

import { LogRow } from '@service';
import { TableValueCell } from '@component';
import { DateTime } from 'luxon';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

export const LogPageTableBodyRow: FunctionComponent<{ row: LogRow }> = ({ row }) => {
  const [open, setOpen] = useState<boolean>(false);

  const onClick = useCallback(() => setOpen((prev) => !prev), [setOpen]);

  return (
    <Fragment>
      <TableRow hover>
        <TableCell component="th">
          <IconButton size="small" onClick={onClick} sx={{ marginRight: 3 }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          {row.id}
        </TableCell>
        <TableValueCell value={row.ip} />
        <TableValueCell value={row.xforwardedFor} />
        <TableValueCell value={row.method} />
        <TableValueCell value={row.path} />
        <TableValueCell value={row.status} />
        <TableValueCell value={DateTime.fromISO(row.requestedAt).toFormat('yyyy-MM-dd HH:mm:ss')} />
        <TableValueCell value={DateTime.fromISO(row.responsedAt).toFormat('yyyy-MM-dd HH:mm:ss')} />
      </TableRow>

      {open && (
        <TableRow>
          <TableCell colSpan={8}>
            <div className="log-detail" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
              <div>
                <h3>Params</h3>
                <pre>{JSON.stringify(row.params, null, 2)}</pre>
              </div>
              <div>
                <h3>Query</h3>
                <pre>{JSON.stringify(row.query, null, 2)}</pre>
              </div>
              <div>
                <h3>Body</h3>
                <pre>{JSON.stringify(row.body, null, 2)}</pre>
              </div>
              <div>
                <h3>Exception</h3>
                <pre>{JSON.stringify(row.exception ?? {}, null, 2)}</pre>
              </div>
            </div>
          </TableCell>
        </TableRow>
      )}
    </Fragment>
  );
};
