import { FunctionComponent } from 'react';

import { TableBody } from '@mui/material';
import { logStore } from '@store';

import { LogPageTableBodyRow } from './log-page-table-body-row';

export const LogPageTableBody: FunctionComponent = () => {
  const { list } = logStore.useValue();

  return (
    <TableBody>
      {list.rows.map((row, i) => (
        <LogPageTableBodyRow key={['log-page-table-row', row.id, i].join('-')} row={row} />
      ))}
    </TableBody>
  );
};
