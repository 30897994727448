import { FunctionComponent } from 'react';
import { RecoilRoot } from 'recoil';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { router } from '@router';
import { versionHook } from '@hook';

const App: FunctionComponent = () => {
  versionHook.useSocketConnect();

  return (
    <RecoilRoot>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </RecoilRoot>
  );
};

export default App;
