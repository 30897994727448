import { useEffect } from 'react';

import { versionSocketService } from '@service';

export class VersionHook {
  useSocketConnect() {
    useEffect(() => {
      versionSocketService.connection();

      return () => {
        versionSocketService.disconnect();
      };
    }, []);
  }
}

export const versionHook = new VersionHook();
