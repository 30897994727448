import { AppConfig } from '@config';
import { SocketService } from '@core';

import { VersionSocketEventName } from './enums';

export class VersionSocketService extends SocketService {
  private readonly appConfig = new AppConfig();

  constructor() {
    super('version');
  }

  connection() {
    if (this.connected) {
      return;
    }

    this.auth = { version: this.appConfig.getAppVersion() };
    this.on(VersionSocketEventName.Refresh, this.onRefresh.bind(this));
    this.connect();
  }

  disconnection() {
    if (this.disconnected) {
      return;
    }

    this.disconnect();
  }

  private onRefresh() {
    window.location.reload();
  }
}

export const versionSocketService = new VersionSocketService();
